<template>
  <div>
    <form @submit.prevent="submit" class="update-password-page">
      <h1 class="text-4xl font-bold mb-1 leading-tight">Update Password</h1>
      <div class="text-sm text-gray-600 mb-4">
        Hi {{ firstName }}, enter a new password to proceed.
      </div>

      <template v-if="getFormError(form)">
        <div class="alert alert-red-soft">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(form) }}</span>
        </div>
      </template>
      <template v-else>
        <div class="h-16"></div>
      </template>

      <form-group
        type="password"
        name="old_password"
        left-icon="lock-closed-outline"
        v-model="form.data.old_password.value"
        :form="form"
      >
        Current password
      </form-group>

      <form-group
        type="password"
        name="password"
        left-icon="lock-closed-outline"
        v-model="form.data.password.value"
        :form="form"
      >
        Enter Password
      </form-group>

      <form-group
        type="password"
        name="confirm_password"
        left-icon="lock-closed-outline"
        v-model="form.data.confirm_password.value"
        :form="form"
      >
        Repeat New Password
      </form-group>

      <div class="text-center">
        <button
          type="submit"
          class="btn btn-blue btn-md w-full"
          :disabled="form.loading"
        >
          <span v-if="form.loading">Submitting...</span>
          <span v-else>Set Password</span>
        </button>
      </div>
    </form>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
      ref="successModal"
    >
      <img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark" />

      <div class="text-lg font-bold mb-4">
        Password Changed
      </div>
      <div class="text-sm mb-10">
        Your password has been updated.
      </div>

      <router-link class="btn btn-blue mr-2 mb-2" :to="{ name: 'dashboard' }">
        Go to dashboard
      </router-link>
    </modal>

    <modal className="w-full md:w-2/5 xl:w-3/10 text-center" ref="helpModal">
      <div class="mb-10">
        <div class="text-xl font-bold mb-10">
          Create a new password.
        </div>

        <div class="font-light text-gray-500 text-sm">
          <p class="mb-2">Create a password to continue using credpal.</p>
        </div>
      </div>

      <button
        type="button"
        class="btn btn-blue-outline btn-md hover:text-white"
        @click.prevent="$refs.helpModal.close"
      >
        Okay
      </button>
    </modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      checkmark: require("@/assets/checkmark-base.svg"),
      form: this.$options.basicForm([
        { name: "old_password", value: "", rules: "required" },
        { name: "password", value: "", rules: "required|min:8|alphaNum" },
        {
          name: "confirm_password",
          value: "",
          rules: "required|same:password",
        },
      ]),
    };
  },
  computed: {
    errorMessage() {
      if (this.form.error.toString().match(/Error: Network Error/i)) {
        return "Please check your internet connection";
      }

      return null;
    },
    sessionExpired() {
      return this.$route.query.expired;
    },
  },
  mounted() {
    // this.$refs.helpModal.open();
  },
  methods: {
    ...mapActions("session", ["updateUser"]),

    closed() {
      console.log("closed");
      this.$refs.successModal.open();
    },
    async submit() {
      if (!this.validateForm(this.form)) {
        return false;
      }

      this.form.loading = true;
      await this.$post({
        url: `${this.$baseurl}/affiliate/profile/change-password`,
        data: {
          old_password: this.form.data.old_password.value,
          password: this.form.data.password.value,
          password_confirmation: this.form.data.confirm_password.value,
        },
        headers: this.headers,
        success: (response) => {
          this.updateUser(response.data.data);
          this.$refs.successModal.open();
        },
        error: (error) => {
          this.form.error = error;
          this.mapFormErrors(this.form, error?.response?.data?.errors);
        },
      });
      this.form.loading = false;
    },
  },
};
</script>
